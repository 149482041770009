import { map } from "lodash";
import Icon from "./Icon";

const IconList = ({ onChange, value, fluid, icons, label, name, ...rest }) => {
  return (
    <div className="flex flex-col gap-4">
      {label && (
        <label htmlFor={name} className="font-bold">
          {label}
        </label>
      )}
      <div className="flex flex-row items-center flex-wrap gap-4" {...rest}>
        {map(icons, (icon) => (
          <div
            key={icon.key}
            className={`flex flex-row items-center gap-2 text-sm border rounded-md shadow-md p-2 ${
              value === icon.value ? "border-blue-600" : "border-blue-100"
            }`}
            role="button"
            aria-hidden="true"
            onClick={() => onChange(icon.value)}
          >
            <Icon name={icon.value} />
            <span>{icon.value}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default IconList;
